<template>
  <div>
    <div id="wrap">      
      <div class="scroll-container">
        <img src="@/assets/img/card/prografTX/TES2024/slide1.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>

import $ from 'jquery';
export default {
  // 추가
  data() {
    return {
      content: '아스텔라스 Prograf Tx_TES2024 (채널톡 발송용)',
    };
  },
  mounted() {
    this._setFirstEvent();
    console.log('test');
  },
  methods: {
    // 추가 // 4자리 인증후 page 0 저장
    _setFirstEvent() {
      console.log('test');
      this.makeLink();
    },
    makeLink() {
      const scale = $(wrap).width() / 1700;

      this.resizeEvent();
      window.addEventListener('resize', this.resizeEvent);

      setTimeout(() => {
        this.setUserScalable();
      }, 1000);
    },
    resizeEvent() {
      const scale = $(wrap).width() / 1700;

    },
    // 추가
    setUserScalable() {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
      }
    },
    // 추가
  },
};
</script>
<style lang="scss">
@charset "utf-8";

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  outline: 0 none;
  padding: 0;
  font-family: 'Noto Sans KR', '돋움', 'Dotum', 'Apple Gothic', sans-serif;
  vertical-align: top;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1;
  color: #333;
  font-weight: 400;
  word-break: break-all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
h1,
h2,
h3,
h4 {
  font-size: 100%;
}
nav ul,
ul,
ol,
li {
  list-style: outside none none;
}
img {
  border: 0 none;
  vertical-align: top;
}
fieldset {
  border: 0 none;
}
a:link {
  text-decoration: none;
  color: #333;
}
a:visited {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #333;
}
a:active {
  text-decoration: none;
  color: #333;
}

* {
  -webkit-text-size-adjust: none;
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  background-color: lightgray; /*-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;*/
}
/* clear */
.clear {
  zoom: 1;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}
/* ir */
.ir_blind {
  height: 0;
  width: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
}

.scroll-container img {
  width: 100%;
}

#wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

#wrap .por {
  position: relative;
}
#wrap .btns {
  text-indent: -9999px;
  position: absolute;
  display: block;
}
#wrap .btn01 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
#wrap .btn02 {
  width: 45%;
  height: 13vw;
  bottom: 43vw;
  left: 50%;
}

@media only screen and (min-width: 1700px) {
  #wrap {
    width: 1700px;
  }
}

.player {
  z-index: 5;
}
/* button{z-index: 10;} */

a.link {
  opacity: 0;
  background-color: transparent;
  z-index: 5;
}

.hcpBox {
  width: 100%;
  height: calc(100vh - 87px);
  background: white;
  text-align: center;
  padding: 150px 0;
}
</style>